import React from "react";
import { connect } from "react-redux";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  ButtonGroup,
  Grid,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

class Header extends React.Component {
  renderHeaderData(title, amount) {
    return (
      <Grid item xs sm container direction="column" spacing={0}>
        <Grid item>
          <Typography gutterBottom variant="subtitle2">
            <div>{title}</div>
            <div>{amount}</div>
          </Typography>
        </Grid>
      </Grid>
    );
  }
  render() {
    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Typography color="inherit" variant="h6">
                  <Button color="inherit" component={RouterLink} to="/member">
                    BaconEggs
                  </Button>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <div>{this.renderHeaderData("Net Worth", "$100,000")}</div>
              </Grid>
              <Grid item xs={2}>
                <div>{this.renderHeaderData("Assets", "$100,000")}</div>
              </Grid>
              <Grid item xs={2}>
                <div>{this.renderHeaderData("Debts", "$100,000")}</div>
              </Grid>
              <Grid item xs>
                <ButtonGroup
                  color="inherit"
                  aria-label="outlined primary button group"
                >
                  <Button
                    color="inherit"
                    variant="outlined"
                    component={RouterLink}
                    to="/member/messages"
                  >
                    Log In
                  </Button>
                  <Button
                    color="inherit"
                    variant="outlined"
                    component={RouterLink}
                    to="/member/settings"
                  >
                    Settings
                  </Button>
                  <Button
                    color="inherit"
                    variant="outlined"
                    component={RouterLink}
                    to="/member/profile"
                  >
                    Profile
                  </Button>
                  <Button
                    color="inherit"
                    variant="outlined"
                    component={RouterLink}
                    to="/member/logout"
                  >
                    Log Out
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    currentUserId: state.auth.userId,
  };
};
export default connect(mapStateToProps)(Header);
