import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  ButtonGroup,
  Grid,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const PublicHeader = () => {
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Grid container>
            <Grid item xs={2}>
              <Typography color="inherit" variant="h6">
                <Button color="inherit" component={RouterLink} to="/public">
                  BaconEggs
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <ButtonGroup color="inherit">
                <Button
                  color="inherit"
                  component={RouterLink}
                  to="/public/howitworks"
                >
                  How it Works
                </Button>
                <Button
                  color="inherit"
                  component={RouterLink}
                  to="/public/products"
                >
                  Products
                </Button>
                <Button
                  color="inherit"
                  component={RouterLink}
                  to="/public/pricing"
                >
                  Pricing
                </Button>
                <Button
                  color="inherit"
                  component={RouterLink}
                  to="/public/security"
                >
                  Security
                </Button>
                <Button
                  color="inherit"
                  component={RouterLink}
                  to="/public/about"
                >
                  About
                </Button>
                <Button
                  color="inherit"
                  component={RouterLink}
                  to="/public/blog"
                >
                  Blog
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={2}>
              <ButtonGroup
                color="inherit"
                aria-label="outlined primary button group"
              >
                <Button
                  color="inherit"
                  variant="outlined"
                  component={RouterLink}
                  to="/public/login"
                >
                  Log In
                </Button>
                <Button
                  color="inherit"
                  variant="outlined"
                  component={RouterLink}
                  to="/public/signup"
                >
                  Sign Up
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default PublicHeader;
