import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Header from "./Header";

import MemberBody from "./MemberBody";
import Profile from "./Profile";
import Settings from "./Settings";
import Messages from "./Messages";
import Logout from "./Logout";

class MemberApp extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Header />
          <Route path="/" exact component={MemberBody} />
          <Route path="/member/" exact component={MemberBody} />
          <Route path="/member/profile" component={Profile} />
          <Route path="/member/settings" component={Settings} />
          <Route path="/member/messages" component={Messages} />
          <Route path="/member/logout" component={Logout} />
        </div>
      </BrowserRouter>
    );
  }
}

export default MemberApp;
