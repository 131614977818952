import React from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { signIn } from "../../actions";

class Login extends React.Component {
  onSignIn(user) {
    let userId = "";
    switch (user) {
      case 1:
        userId = "sbMem5f407207d486d1";
        break;
      case 2:
        userId = "sbMem5f407207d486d2";
        break;
      case 3:
        userId = "sbMem5f407207d486d3";
        break;
      case 4:
        userId = "sbMem5f407207d486d4";
        break;
      case 5:
        userId = "sbMem5f407207d486d5";
        break;
      default:
        userId = "sbMem5f407207d486d1";
    }
    console.log("userId= ", userId);
    this.props.signIn(userId, null);
  }
  render() {
    return (
      <div>
        Please Login:
        <Button color="inherit" onClick={() => this.onSignIn(1)}>
          Test User 1
        </Button>
        <Button color="inherit" onClick={() => this.onSignIn(2)}>
          Test User 2
        </Button>
        <Button color="inherit" onClick={() => this.onSignIn(3)}>
          Test User 3
        </Button>
        <Button color="inherit" onClick={() => this.onSignIn(4)}>
          Test User 4
        </Button>
        <Button color="inherit" onClick={() => this.onSignIn(5)}>
          Test User 5
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn };
};

export default connect(mapStateToProps, { signIn })(Login);
