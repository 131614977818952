import React from "react";
import Chart from "./Charts/Chart";
import PieChart from "./NivoCharts/PieChart";
import BarChart from "./NivoCharts/BarChart";
import LineChart from "./NivoCharts/LineChart";
import { Grid } from "@material-ui/core";
import { getData } from "./Charts/utils";

class DashboardContent extends React.Component {
  data1 = [
    {
      id: "sass",
      label: "sass",
      value: 98,
      color: "hsl(100, 70%, 50%)",
    },
    {
      id: "hack",
      label: "hack",
      value: 539,
      color: "hsl(29, 70%, 50%)",
    },
    {
      id: "java",
      label: "java",
      value: 499,
      color: "hsl(14, 70%, 50%)",
    },
    {
      id: "css",
      label: "css",
      value: 308,
      color: "hsl(339, 70%, 50%)",
    },
    {
      id: "stylus",
      label: "stylus",
      value: 471,
      color: "hsl(75, 70%, 50%)",
    },
    {
      id: "javascript",
      label: "javascript",
      value: 309,
      color: "hsl(166, 70%, 50%)",
    },
    {
      id: "ruby",
      label: "ruby",
      value: 411,
      color: "hsl(11, 70%, 50%)",
    },
    {
      id: "lisp",
      label: "lisp",
      value: 518,
      color: "hsl(33, 70%, 50%)",
    },
    {
      id: "scala",
      label: "scala",
      value: 561,
      color: "hsl(30, 70%, 50%)",
    },
    {
      id: "elixir",
      label: "elixir",
      value: 245,
      color: "hsl(253, 70%, 50%)",
    },
    {
      id: "haskell",
      label: "haskell",
      value: 93,
      color: "hsl(324, 70%, 50%)",
    },
    {
      id: "c",
      label: "c",
      value: 483,
      color: "hsl(274, 70%, 50%)",
    },
    {
      id: "python",
      label: "python",
      value: 233,
      color: "hsl(105, 70%, 50%)",
    },
    {
      id: "php",
      label: "php",
      value: 268,
      color: "hsl(116, 70%, 50%)",
    },
    {
      id: "make",
      label: "make",
      value: 142,
      color: "hsl(273, 70%, 50%)",
    },
    {
      id: "rust",
      label: "rust",
      value: 440,
      color: "hsl(231, 70%, 50%)",
    },
    {
      id: "erlang",
      label: "erlang",
      value: 265,
      color: "hsl(1, 70%, 50%)",
    },
    {
      id: "go",
      label: "go",
      value: 385,
      color: "hsl(277, 70%, 50%)",
    },
  ];
  data2a = [
    {
      country: "Education",
      "hot dog": 55,
      "hot dogColor": "hsl(7, 70%, 50%)",
      burger: 45,
      burgerColor: "hsl(36, 70%, 50%)",
    },
  ];
  data2b = [
    {
      country: "Wedding",
      "hot dog": 35,
      "hot dogColor": "hsl(7, 70%, 50%)",
      burger: 65,
      burgerColor: "hsl(36, 70%, 50%)",
    },
  ];

  data2c = [
    {
      country: "Retirement",
      "hot dog": 85,
      "hot dogColor": "hsl(7, 70%, 50%)",
      burger: 15,
      burgerColor: "hsl(36, 70%, 50%)",
    },
  ];

  data3 = [
    {
      id: "japan",
      color: "hsl(89, 70%, 50%)",
      data: [
        {
          x: "plane",
          y: 179,
        },
        {
          x: "helicopter",
          y: 46,
        },
        {
          x: "boat",
          y: 15,
        },
        {
          x: "train",
          y: 74,
        },
        {
          x: "subway",
          y: 260,
        },
        {
          x: "bus",
          y: 94,
        },
        {
          x: "car",
          y: 158,
        },
        {
          x: "moto",
          y: 171,
        },
        {
          x: "bicycle",
          y: 116,
        },
        {
          x: "horse",
          y: 75,
        },
        {
          x: "skateboard",
          y: 96,
        },
        {
          x: "others",
          y: 64,
        },
      ],
    },
    {
      id: "france",
      color: "hsl(27, 70%, 50%)",
      data: [
        {
          x: "plane",
          y: 159,
        },
        {
          x: "helicopter",
          y: 117,
        },
        {
          x: "boat",
          y: 136,
        },
        {
          x: "train",
          y: 92,
        },
        {
          x: "subway",
          y: 239,
        },
        {
          x: "bus",
          y: 35,
        },
        {
          x: "car",
          y: 185,
        },
        {
          x: "moto",
          y: 118,
        },
        {
          x: "bicycle",
          y: 74,
        },
        {
          x: "horse",
          y: 219,
        },
        {
          x: "skateboard",
          y: 147,
        },
        {
          x: "others",
          y: 133,
        },
      ],
    },
    {
      id: "us",
      color: "hsl(120, 70%, 50%)",
      data: [
        {
          x: "plane",
          y: 297,
        },
        {
          x: "helicopter",
          y: 177,
        },
        {
          x: "boat",
          y: 26,
        },
        {
          x: "train",
          y: 150,
        },
        {
          x: "subway",
          y: 56,
        },
        {
          x: "bus",
          y: 56,
        },
        {
          x: "car",
          y: 21,
        },
        {
          x: "moto",
          y: 45,
        },
        {
          x: "bicycle",
          y: 121,
        },
        {
          x: "horse",
          y: 167,
        },
        {
          x: "skateboard",
          y: 92,
        },
        {
          x: "others",
          y: 149,
        },
      ],
    },
    {
      id: "germany",
      color: "hsl(102, 70%, 50%)",
      data: [
        {
          x: "plane",
          y: 255,
        },
        {
          x: "helicopter",
          y: 148,
        },
        {
          x: "boat",
          y: 151,
        },
        {
          x: "train",
          y: 145,
        },
        {
          x: "subway",
          y: 147,
        },
        {
          x: "bus",
          y: 109,
        },
        {
          x: "car",
          y: 12,
        },
        {
          x: "moto",
          y: 37,
        },
        {
          x: "bicycle",
          y: 269,
        },
        {
          x: "horse",
          y: 298,
        },
        {
          x: "skateboard",
          y: 291,
        },
        {
          x: "others",
          y: 120,
        },
      ],
    },
    {
      id: "norway",
      color: "hsl(183, 70%, 50%)",
      data: [
        {
          x: "plane",
          y: 204,
        },
        {
          x: "helicopter",
          y: 114,
        },
        {
          x: "boat",
          y: 134,
        },
        {
          x: "train",
          y: 56,
        },
        {
          x: "subway",
          y: 160,
        },
        {
          x: "bus",
          y: 171,
        },
        {
          x: "car",
          y: 38,
        },
        {
          x: "moto",
          y: 230,
        },
        {
          x: "bicycle",
          y: 92,
        },
        {
          x: "horse",
          y: 124,
        },
        {
          x: "skateboard",
          y: 265,
        },
        {
          x: "others",
          y: 206,
        },
      ],
    },
  ];
  componentDidMount() {
    getData().then((data) => {
      this.setState({ data });
    });
  }
  render() {
    if (this.state == null) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        <Grid item xs={12} container direction="column" spacing={2}>
          <Grid item>
            <Chart type="hybrid" data={this.state.data} />;
          </Grid>
          <Grid item container spacing={0}>
            <Grid item xs={6}>
              <div style={{ height: 300 }}>
                <LineChart data={this.data3} />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ height: 300 }}>
                <PieChart data={this.data1} />
              </div>
            </Grid>
          </Grid>
          <Grid item container spacing={0}>
            <Grid item xs={4}>
              <div style={{ height: 300 }}>
                <BarChart data={this.data2a} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div style={{ height: 300 }}>
                <BarChart data={this.data2b} />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div style={{ height: 300 }}>
                <BarChart data={this.data2c} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default DashboardContent;
