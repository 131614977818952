import React from "react";
import { Grid } from "@material-ui/core";

import LeftMenuBar from "./LeftMenuBar";
import Content from "./Content";

class MemberBody extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <LeftMenuBar />
          </Grid>
          <Grid item xs={11}>
            <Content />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default MemberBody;
