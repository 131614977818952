import { FETCH_ACCOUNTS } from "../actions/types";

export default (state = { accounts: [] }, action) => {
  switch (action.type) {
    case FETCH_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
      };
    default:
      return state;
  }
};
