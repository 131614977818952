import React from "react";
import { connect } from "react-redux";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { fetchAccounts } from "../../actions";

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

class AccountsBar extends React.Component {
  componentDidMount() {
    this.props.fetchAccounts(this.props.userId);
  }
  renderAccounts() {
    return (
      <div>
        <Grid container direction="column">
          {this.props.accounts.map((account) => {
            return this.renderAccount(account);
          })}
        </Grid>
      </div>
    );
  }
  renderAccount(account) {
    return (
      <Grid item>
        <Table size="small">
          <TableBody>
            <TableRow key={account.accountName}>
              <TableCell align="left" colSpan={2}>
                {account.accountName}
              </TableCell>
            </TableRow>
            <TableRow key={account.id}>
              <TableCell align="left">{account.providerName}</TableCell>
              <TableCell align="right">
                Balance: {formatter.format(account.balance.amount)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    );
  }
  render() {
    console.log(this.props.accounts);
    return <div>{this.renderAccounts()}</div>;
  }
}

const mapStateToProps = (state) => {
  return { userId: state.auth.userId, accounts: state.accounts.accounts };
};
export default connect(mapStateToProps, { fetchAccounts })(AccountsBar);
