import React from "react";
import { connect } from "react-redux";
import {
  LBAR_ADD_ACCOUNTS,
  LBAR_DASHBOARD,
  LBAR_BUDGET,
  LBAR_PORTFOLIO,
  LBAR_ANALYZE,
  LBAR_INVEST,
  LBAR_COMPARE,
  LBAR_TOOLS,
} from "../../actions/types";

import Dashboard from "./Dashboard";

class Content extends React.Component {
  renderContent() {
    console.log(this.props.lbarSelectedItem);
    switch (this.props.lbarSelectedItem) {
      case LBAR_ADD_ACCOUNTS:
        return <div>Add Accounts</div>;
      case LBAR_DASHBOARD:
        return (
          <div>
            <Dashboard />
          </div>
        );

      case LBAR_BUDGET:
        return <div>Budget</div>;

      case LBAR_PORTFOLIO:
        return <div>Portfolio</div>;

      case LBAR_ANALYZE:
        return <div>Analyze</div>;

      case LBAR_INVEST:
        return <div>Invest</div>;

      case LBAR_COMPARE:
        return <div>Compare</div>;

      case LBAR_TOOLS:
        return <div>Tools</div>;

      default:
        return <div>Defaulting to Dashboard</div>;
    }
  }
  render() {
    return <div>{this.renderContent()}</div>;
  }
}

const mapStateToProps = (state) => {
  return { lbarSelectedItem: state.lbar.lbarMenuSelectedItem };
};
export default connect(mapStateToProps)(Content);
