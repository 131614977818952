import React from "react";
import { Route, BrowserRouter } from "react-router-dom";

import PublicHeader from "./PublicHeader";
import PublicBody from "./PublicBody";
import HowItWorks from "./HowItWorks";
import Products from "./Products";
import Pricing from "./Pricing";
import Security from "./Security";
import About from "./About";
import Blog from "./Blog";
import SignUp from "./SignUp";
import Login from "./Login";

class PublicApp extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <PublicHeader />
          <Route path="/public/" exact component={PublicBody} />
          <Route path="/public/howitworks" component={HowItWorks} />
          <Route path="/public/products" component={Products} />
          <Route path="/public/pricing" component={Pricing} />
          <Route path="/public/security" component={Security} />
          <Route path="/public/about" component={About} />
          <Route path="/public/blog" component={Blog} />
          <Route path="/public/signup" component={SignUp} />
          <Route path="/public/login" component={Login} />
        </div>
      </BrowserRouter>
    );
  }
}

export default PublicApp;
