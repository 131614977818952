import React from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import AccountsBar from "./AccountsBar";
import DashboardContent from "./DashboardContent";
import TransactionsBar from "./TransactionsBar";

class Dashboard extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item>
            <AccountsBar />
          </Grid>
          <Grid item xs={7}>
            <DashboardContent />
          </Grid>
          <Grid item>
            <TransactionsBar />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};
export default connect(mapStateToProps)(Dashboard);
