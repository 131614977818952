import { combineReducers } from "redux";
import authReducer from "./authReducer";
import lbarReducer from "./lbarReducer";
import accountsReducer from "./accountsReducer";
import transactionsReducer from "./transactionsReducer";

export default combineReducers({
  auth: authReducer,
  lbar: lbarReducer,
  accounts: accountsReducer,
  transactions: transactionsReducer,
});
