import React from "react";
import { connect } from "react-redux";
import {
  FilledInput,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { fetchTransactions } from "../../actions";

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

class TransactionsBar extends React.Component {
  componentDidMount() {
    this.props.fetchTransactions(this.props.userId);
  }
  renderTransactions() {
    return (
      <div>
        <Grid container direction="column">
          {this.props.transactions.map((transaction) => {
            return this.renderTransaction(transaction);
          })}
        </Grid>
      </div>
    );
  }
  renderTransaction(transaction) {
    console.log("Printing transaction");
    console.log(transaction);
    return (
      <Grid item>
        <Table size="small">
          <TableBody>
            <TableRow key={transaction.id}>
              <TableCell align="left" colSpan={2}>
                {transaction.date}
              </TableCell>
              <TableCell align="right" colSpan={2}>
                {formatter.format(transaction.amount.amount)}
              </TableCell>
            </TableRow>
            <TableRow key={transaction.id}>
              <TableCell align="left">{transaction.category}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    );
  }
  render() {
    console.log(this.props.transactions);
    return <div>{this.renderTransactions()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    transactions: state.transactions.transactions,
  };
};
export default connect(mapStateToProps, { fetchTransactions })(TransactionsBar);
