import React from "react";
import {
   Typography,
  Button,
  Grid,
 } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import { handleLBarSelection } from "../../actions";
import {
  LBAR_ADD_ACCOUNTS,
  LBAR_DASHBOARD,
  LBAR_BUDGET,
  LBAR_PORTFOLIO,
  LBAR_ANALYZE,
  LBAR_INVEST,
  LBAR_COMPARE,
  LBAR_TOOLS,
} from "../../actions/types";
class LeftMenuBar extends React.Component {
  handleLBarClick(selectedMenuItem) {
    this.props.handleLBarSelection(selectedMenuItem);
  }
  render() {
    return (
      <div>
        <Grid container direction="column">
          <Grid item xs>
            <Typography color="inherit" variant="h6">
              <Button
                color="inherit"
                onClick={() => this.handleLBarClick(LBAR_ADD_ACCOUNTS)}
              >
                Add Accounts
              </Button>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="inherit" variant="h6">
              <Button
                color="inherit"
                onClick={() => this.handleLBarClick(LBAR_DASHBOARD)}
              >
                Dashboard
              </Button>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="inherit" variant="h6">
              <Button
                color="inherit"
                onClick={() => this.handleLBarClick(LBAR_BUDGET)}
              >
                Budget
              </Button>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="inherit" variant="h6">
              <Button
                color="inherit"
                onClick={() => this.handleLBarClick(LBAR_PORTFOLIO)}
              >
                Portfolio
              </Button>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="inherit" variant="h6">
              <Button
                color="inherit"
                onClick={() => this.handleLBarClick(LBAR_ANALYZE)}
              >
                Analyze
              </Button>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="inherit" variant="h6">
              <Button
                color="inherit"
                onClick={() => this.handleLBarClick(LBAR_INVEST)}
              >
                Invest
              </Button>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="inherit" variant="h6">
              <Button
                color="inherit"
                onClick={() => this.handleLBarClick(LBAR_COMPARE)}
              >
                Compare
              </Button>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="inherit" variant="h6">
              <Button
                color="inherit"
                onClick={() => this.handleLBarClick(LBAR_TOOLS)}
              >
                Tools
              </Button>
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography color="inherit" variant="h6">
              <Button color="inherit" component={RouterLink} to="/public/blog">
                Blog
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect(null, { handleLBarSelection })(LeftMenuBar);
