import { LBAR_HANDLE_SELECTION, LBAR_DASHBOARD } from "../actions/types";

export default (state = { lbarMenuSelectedItem: LBAR_DASHBOARD }, action) => {
  switch (action.type) {
    case LBAR_HANDLE_SELECTION:
      return {
        ...state,
        lbarMenuSelectedItem: action.payload,
      };
    default:
      return {
        ...state,
        lbarMenuSelectedItem: LBAR_DASHBOARD,
      };
  }
};
