export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

export const LBAR_HANDLE_SELECTION = "LBAR_HANDLE_SELECTION";
export const LBAR_ADD_ACCOUNTS = "LBAR_ADD_ACCOUNTS";
export const LBAR_DASHBOARD = "LBAR_DASHBOARD";
export const LBAR_BUDGET = "LBAR_BUDGET";
export const LBAR_PORTFOLIO = "LBAR_PORTFOLIO";
export const LBAR_ANALYZE = "LBAR_ANALYZE";
export const LBAR_INVEST = "LBAR_INVEST";
export const LBAR_COMPARE = "LBAR_COMPARE";
export const LBAR_TOOLS = "LBAR_TOOLS";
export const LBAR_BLOG = "LBAR_BLOG";

export const FETCH_ACCOUNTS = "FETCH_ACCOUNTS";
export const FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS";
