import React from "react";
import { connect } from "react-redux";

import MemberApp from "./member/MemberApp";
import PublicApp from "./public/PublicApp";

class App extends React.Component {
  renderApp() {
    if (this.props.isSignedIn) {
      return (
        <div>
          <MemberApp />
        </div>
      );
    }
    return (
      <div>
        <PublicApp />
      </div>
    );
  }

  render() {
    return this.renderApp();
  }
}

const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn, userId: state.auth.userId };
};

export default connect(mapStateToProps)(App);
