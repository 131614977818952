import {
  SIGN_IN,
  SIGN_OUT,
  LBAR_HANDLE_SELECTION,
  FETCH_ACCOUNTS,
  FETCH_TRANSACTIONS,
} from "./types";
import Yodlee from "../apis/Yodlee";
export const signIn = (userId, googleAuth) => {
  return {
    type: SIGN_IN,
    payload: { userId, googleAuth },
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT,
  };
};

export const handleLBarSelection = (lbarMenuSelectedItem) => {
  return {
    type: LBAR_HANDLE_SELECTION,
    payload: lbarMenuSelectedItem,
  };
};

export const fetchAccounts = (userId) => async (dispatch) => {
  console.log(userId);
  const response = await Yodlee.get("/accounts");
  console.log(response.data);
  dispatch({
    type: FETCH_ACCOUNTS,
    payload: response.data,
  });
};

export const fetchTransactions = (userId) => async (dispatch) => {
  console.log(userId);
  const response = await Yodlee.get("/transactions");
  console.log(response.data);
  dispatch({
    type: FETCH_TRANSACTIONS,
    payload: response.data,
  });
};
