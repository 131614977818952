import axios from "axios";

export default axios.create({
  baseURL: "https://qm42takmbk.execute-api.us-east-1.amazonaws.com/prod",
  baseURL: " https://jz2i5kaifk.execute-api.us-east-1.amazonaws.com/Test",

  headers: {
    Authorization: "Client-ID 0Hy7utarFM29GhtU2OO-lyf9gKilml2IlyAYLjstR4Y",
  },
});
